import React from 'react';
import ComingSoon from 'react-coming-soon';
import logo from './logo.png'
import './App.css';

 
export default function App() {
  return (
      <div className="App">
		<div className="App-header">
			<img src={logo} alt="logo" />
		</div>
		<ComingSoon title="Coming Soon" bgColor="#fff" textColor="#212121" illustration="web-development" />
	</div>
  );
}